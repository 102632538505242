
import React from 'react'
import ProjectHeader from '../../components/Header/ProjectHeader'
import { mypx,  xgap, tc, col2} from '../../constants/spacing'
import DesktopVid from '../../video/hf-vid-desktop.mp4'
import MobileVid from '../../video/hf-vid-mobile.mp4'
import { StaticImage } from 'gatsby-plugin-image'
import {SimpleGrid, Grid, GridItem, } from '@chakra-ui/react'
import SilentPlayer from '../../components/video/SilentPlayer'
import SusVid from '../../video/hf-sus-vid-3br.mp4'
import History from '../../images/hf/box-history-gif.gif'
import Dissection from '../../images/hf/dissection.gif'
import MyPlayer from '../../components/MyPlayer'
import Retail from '../../images/hf/retail-motion-gif.gif'
import HeMotion from '../../images/hf/he-motion-2.gif'
import HeMotion2 from '../../images/hf/he-motion-1.gif'
import Cheering from '../../images/hf/cheering-mod-psd.gif'
import Factor from '../../images/hf/factor-motion.gif'
import jam from '../../images/hf/jam.gif'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'
import ProjectArrows, { ProjectLinkers } from '../../components/Header/ProjectArrows'
const preventClick = (e) => e.preventDefault()
//Updated copy on this page 11-2-2021 via Wordpress. Pushing update in order to rebuild site w/ new copy. 

//Fix WP Gatsby

const HellofreshPackaging = ({data, location}) => {
    const dat = data.wpProject.hollandProjects;
    return (
        <>
        <SEO title={dat.projecttitle} description={dat.projectdescription} image='../../images/hf/img1.jpg' url="https://hollandhames.com/project/hellofresh-packaging"/>
        <ProjectHeader desktopVid={DesktopVid} mobileVid={MobileVid} title={dat.projecttitle} description={dat.projectdescription} />
        <SimpleGrid columns={1} gap={xgap} px={mypx} pb={xgap}>
            <Grid gap={xgap} templateColumns={["(1, 1fr)", null,null,null,"(2, 1fr)"]} templateRows={["(4, 1fr)", null,null,null,"(7, 1fr)"]}>
            <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[1,null,null,null,3]}>
                <StaticImage onContextMenu={preventClick} src="../../images/hf/img1.jpg" />
            </GridItem>
            <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,2]} rowEnd={[2,null,null,null,4]}>
                <StaticImage onContextMenu={preventClick} src="../../images/hf/img2.jpg" />
            </GridItem>
            <GridItem colStart={1} colEnd={1} rowStart={[3,null,null,null,3]} rowEnd={[3,null,null,null,5]}>
                <StaticImage onContextMenu={preventClick} src="../../images/hf/img3.jpg" />
            </GridItem>
            <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[4,null,null,null,4]} rowEnd={[4,null,null,null,7]}>
                <StaticImage onContextMenu={preventClick} src="../../images/hf/img4.jpg" />
            </GridItem>
            </Grid>
            <SimpleGrid columns={1} gap={xgap}>
                <SilentPlayer url={SusVid} />
            </SimpleGrid>
            <SimpleGrid justifyItems="center" gap={xgap}>
                <img onContextMenu={preventClick} onContextMenu={preventClick} src={History} />
            </SimpleGrid>
            <Grid gap={xgap} templateColumns={tc} templateRows={["(2, 1fr)", null,null,null,"(5, 1fr)"]}>
                <GridItem colStart={1} colEnd={1} rowStart={[1,null,null,null,1]} rowEnd={[1,null,null,null,3]}>
                    <StaticImage onContextMenu={preventClick} src="../../images/hf/img6.jpg" />
                </GridItem>
                <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,2]} rowEnd={[2,null,null,null,5]}>
                    <StaticImage onContextMenu={preventClick} src='../../images/hf/img7-3.jpg' />
                </GridItem>
            </Grid>
            <SimpleGrid justifyItems="center" gap={xgap}>
                <img onContextMenu={preventClick} src={Dissection} />
            </SimpleGrid>
            <SimpleGrid justifyItems="center" gap={xgap}>
                <StaticImage onContextMenu={preventClick} src='../../images/hf/kitbag-set.jpg' />
            </SimpleGrid>
            <SimpleGrid  gap={xgap} justifyItems="center">
                <StaticImage onContextMenu={preventClick} src='../../images/hf/pasta.png' />
            </SimpleGrid>
            <SimpleGrid columns={col2} gap={xgap}>
                <img onContextMenu={preventClick} src={jam} /> <StaticImage onContextMenu={preventClick} src='../../images/hf/beans.png' />
            </SimpleGrid>
            <SimpleGrid justifyItems="center" gap={xgap}>
                <MyPlayer /> 
            </SimpleGrid>
            <SimpleGrid justifyItems="center" gap={xgap}>
                <img onContextMenu={preventClick} src={Retail} />
            </SimpleGrid>
            <SimpleGrid justifyItems="center" gap={xgap}>
                <StaticImage onContextMenu={preventClick} src='../../images/hf/retail-bottom-group.jpg' />
            </SimpleGrid>
            <Grid gap={xgap} templateColumns={tc} templateRows={["(2, 1fr)",null,null,null,"(5, 1fr)"]}>
                <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[1,null,null,null,3]}>
                    <img onContextMenu={preventClick} src={HeMotion} />
                </GridItem>
                <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={2} rowEnd={[2,null,null,null,5]}>
                    <StaticImage onContextMenu={preventClick} src='../../images/hf/he-2.png' />
                </GridItem>
            </Grid>
            <SimpleGrid gap={xgap}>
                <img onContextMenu={preventClick} src={HeMotion2} />
            </SimpleGrid>
            <Grid gap={xgap} templateColumns={tc} templateRows={["(2, 1fr)",null,null,null,"(3, 1fr)"]}>
                <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[2,null,null,null,3]}>
                    <StaticImage onContextMenu={preventClick} src='../../images/hf/black1.png' />
                </GridItem>
                <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={2} rowEnd={[2,null,null,null,3]}>
                    <StaticImage onContextMenu={preventClick} src='../../images/hf/black-envelope.png' />
                </GridItem>
            </Grid>
            <SimpleGrid gap={xgap}>
                <StaticImage onContextMenu={preventClick} src='../../images/hf/blackbox-angle-bags.png' />
            </SimpleGrid>
            <SimpleGrid gap={xgap}>
                <StaticImage onContextMenu={preventClick} src='../../images/hf/img3.jpg' />
            </SimpleGrid>
            <Grid gap={xgap} templateColumns={tc} templateRows={["(2, 1fr)",null,null,null,"(5, 1fr)"]}>
                <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[2,null,null,null,4]}>
                    <StaticImage onContextMenu={preventClick} src='../../images/hf/market.png' />
                </GridItem>
                <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,3]} rowEnd={[2,null,null,null,5]}>
                    <img onContextMenu={preventClick} src={Cheering} />
                </GridItem>
            </Grid>
            <SimpleGrid gap={xgap}>
                <img onContextMenu={preventClick} src={Factor} />
            </SimpleGrid>
            <SimpleGrid gap={xgap}>
                <StaticImage onContextMenu={preventClick} src="../../images/hf/market2.jpg" />
            </SimpleGrid>
        </SimpleGrid>
        <ProjectLinkers prevTitle='VPA' prevUrl='/project/vpa' nextTitle='Hellofresh Marketing' nextUrl='/project/hellofresh-marketing' />
    </>
)
}

export default HellofreshPackaging

export const query = graphql`
{
    wpProject(title: {eq: "Hellofresh Packaging"}) {
        hollandProjects {
            projecttitle
            projectdescription
            projectcategories
        }
    }
}

`


